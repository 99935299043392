import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { ApproveDeviceBindingRequest, BindResorcesRequest, BindResorcesRequests, DeclineBindResourceRequest } from './types'
import { ApiRoutes } from '../../../../utils/api-routes'

export const mechanicBindRequestsApi = createApi({
    reducerPath: 'mechanicBindRequestsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getBindEquipmentRequests: builder.query<BindResorcesRequest[], void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const response = await fetchWithBQ(ApiRoutes.getBindEquipmentRequests)
                if (response.error) {
                    return { error: response.error }
                }
                
                return { data: (response.data as BindResorcesRequests).resources}
            }
        }),
        declineDeviceBinding: builder.mutation<any, DeclineBindResourceRequest>({
            query: (request) => ({
                url: ApiRoutes.declineDeviceBinding,
                method: 'POST',
                body: request
            }),
        }),
        approveDeviceBinding: builder.mutation<any, ApproveDeviceBindingRequest>({
            query: (request) => ({
                url: ApiRoutes.approveDeviceBinding,
                method: 'POST',
                body: request
            }),
        }),
    }),
  })
  
  export const {
    useGetBindEquipmentRequestsQuery,
    useDeclineDeviceBindingMutation,
    useApproveDeviceBindingMutation,
} = mechanicBindRequestsApi