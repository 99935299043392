import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { MechanicBindDeviceRequest } from './types'
import { StorageKeys } from '../../../utils/storage-keys'
import { ApiRoutes } from '../../../utils/api-routes'

export const mechanicBindDeviceApi = createApi({
    reducerPath: 'mechanicBindDeviceApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        bindDevice: builder.mutation<void, MechanicBindDeviceRequest>({
            query: (request) => ({
                url: ApiRoutes.bindDevice,
                method: 'POST',
                body: request
            }),
            transformResponse: async (response, meta: FetchBaseQueryMeta) => {
                const deviceId = meta?.response?.headers.get('X-Gateway-Mobile-Deviceid')
                if (deviceId) {
                    localStorage.setItem(StorageKeys.deviceId, deviceId)
                }
            }
        }),
    }),
  })
  
  export const { useBindDeviceMutation } = mechanicBindDeviceApi