import type { Style } from 'mapbox-gl';

export const MAX_ZOOM = 17;
export const DEFAULT_ZOOM = 10;
export const ROAD_POI_ZOOM = 10;

// TODO remove after geoserver update
export const DEFAULT_CENTER: [number, number] = [69.914736, 60.746702];

export const MAP_STYLE: Style = {
    version: 8,
    glyphs: '/fonts_pbf/{fontstack}/{range}.pbf',
    sources: {
      //Основные плитки, близкое маштабирование
      // 'raster-tiles': {
      //   type: 'raster',
      //   tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      //   // tiles: [
      //   //   `/geoserver/ows?LAYERS=GPN_070_topo%3Av_nature_green_surf_100k_ma%2CGPN_070_topo%3Av_nature_green_obj_100k_mp,GPN_070_topo%3Av_nature_ground_surf_100k_ma,GPN_070_topo%3Av_nature_ground_obj_100k_mp,GPN_070_topo%3Av_nature_swamp_surf_100k_ma,GPN_070_topo%3Av_nature_swamp_obj_100k_mp,GPN_070_topo%3Av_nature_hydro_water_100k_ma,GPN_070_topo%3Av_nature_hydro_obj_100k_ma,GPN_070_topo%3Av_nature_hydro_river_100k_ml,GPN_070_topo%3Av_nature_hydro_obj_100k_ml,GPN_070_topo%3Av_nature_hydro_coast_100k_ml,GPN_070_topo%3Av_nature_hydro_obj_100k_mp,GPN_070_topo%3Av_nature_relief_surf_100k_ma,GPN_070_topo%3Av_nature_relief_obj_100k_ml,GPN_070_topo%3Av_nature_relief_horiz_100k_ml,GPN_070_topo%3Av_nature_relief_obj_100k_mp,GPN_070_topo%3Av_nature_relief_alt_100k_p&FORMAT=image%2Fpng&TRANSPARENT=TRUE&ENV=fontSizeCoef%3A1&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&FORMAT_OPTIONS=dpi%3A96&SRS=EPSG%3A3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256`,
      //   // ],
      //   tileSize: 256,
      // },
      //Плитки дальнего маштабирования
      // 'large-scale': {
      //   type: 'raster',
      //   tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      //   // tiles: [
      //   //   `/geoserver/ows?LAYERS=GPN_070_topo_cache:Topograficheskie_karty&FORMAT=image%2Fpng&TRANSPARENT=TRUE&ENV=fontSizeCoef%3A1&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&FORMAT_OPTIONS=dpi%3A96&SRS=EPSG%3A3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256`,
      //   // ],
      //   tileSize: 256,
      // },
      //Слой для записи geojson c информацией о размерах кустов
      // 'prom-area': {
      //   type: 'geojson',
      //   data: {
      //     type: 'FeatureCollection',
      //     features: [],
      //   },
      // },
    },
    layers: [
      {
        id: 'background',
        type: 'background',
        paint: {
          'background-color': '#CCDFC0',
        },
        interactive: true,
      },  
      //Топографические плитки (большой маштаб)
      // {
      //   id: 'large-scale-tiles',
      //   type: 'raster',
      //   source: 'large-scale',
      //   maxzoom: 10,
      //   layout: {
      //     visibility: 'visible',
      //   },
      // },
      // //Топографические плитки (малый маштаб)
      // {
      //   id: 'simple-tiles',
      //   type: 'raster',
      //   source: 'raster-tiles',
      //   minzoom: 9,
      //   layout: {
      //     visibility:'visible',
      //   },
      // },
      // // Заливка полигонов кутсов
      // {
      //   id: 'well-pad-fill',
      //   type: 'fill',
      //   source: 'prom-area',
      //   minzoom: 9.5,
      //   paint: {
      //     'fill-color': '#E7DCC8',
      //     'fill-opacity': 1,
      //   },
      //   layout: {
      //     visibility: 'visible',
      //   },
      // },
      // // Обводка контура (полигонов) кутсов
      // {
      //   id: 'well-pad-contour',
      //   type: 'line',
      //   source: 'prom-area',
      //   minzoom: 9.5,
      //   paint: {
      //     'line-color': '#A6A198',
      //     'line-width': 1.5,
      //   },
      //   layout: {
      //     visibility: 'visible',
      //   },
      // },
    ],
};
