import { MdmWorkShift } from "../../../mdm/types"

export interface MasterScheduleData {
    shift: MdmWorkShift
    requests: MasterRequest[]
    newMessage: boolean
}

export interface MasterRequests {
    requests: MasterRequest[] | undefined
    newMessage: boolean
}

export interface MasterRequest {
    id: string
    name: string
    reqNum: number
    // regDt: Date
    type: MasterRequestType
    state: MasterRequestState
    beginDate: string
    endDate: string
    equipmentItemId: string
    jobs: MasterJob[]
    locations: MasterLocation[]
    equipmentTypeParamsLoading: EquipmentTypeParams[]
    equipmentTypeParamsUnloading: EquipmentTypeParams[]
    equipmentTypeParamsTransportations: EquipmentTypeParams[]
    hasFactSlots: boolean
    pickUpLocation?: Location
    dropLocations?: Location[]
    createdBy: string
    phoneNumber?: string
}

interface MasterJob {
    id: string
    locationId: string
    wellPadTitle: string
    wellPadGroupTitle: string
    wellId: string
    wellTitle: string
    poiId: string
    stepTitle: string
    loadingDuration: number
    unloadingDuration: number
    isShuttle: boolean
    uniformDistribution: boolean
    maxResourceCount: number
    equipmentTypeParams: EquipmentTypeParams[]
    materialRequirements: MaterialRequirements[]
    poiTypes: string[]
    destinationPoint: {
        lat: number,
        lng: number
    }
}

interface EquipmentTypeParams {
    count: number
    equipmentTypeId: string
    equipmentTitle: string

}

interface MaterialRequirements {
    id: string
    materialId: string
    qty: number
}

interface MasterLocation {
    id: string
    locationId: string
    wellPadTitle: string
    wellPadGroupTitle: string
    wellId: string
    wellTitle: string
    poiId: string
    comment: string
    duration: number
}

interface Location {
    destinationPoint: DestinationPoint
    poiId: string
    deleted: boolean
    id: string
    wellPadGroupTitle: string
    wellPadTitle: string
    planSlots: SlotDetails[]
    factSlots: SlotDetails[]
    poiTypes: string[]
}

interface DestinationPoint {
    lat: number
    lng: number
}

interface SlotDetails {
    beginDate: string
    endDate: string
    type: SlotType
    id: string
    state: MasterRequestState
    titleOf: string
    titleTo: string
}

export enum MasterRequestState {
    PLANNED = "PLANNED",
    NOT_PLANNED = "NOT_PLANNED",
    DELAYED = "DELAYED",
    IN_PROGRESS = "IN_PROGRESS",
    LOCKED = "LOCKED",
    LOCKED_AUTO = "LOCKED_AUTO",
    CONFIRMED = "CONFIRMED",
    NOT_PERFORMED = "NOT_PERFORMED",
    DEFICIT = "DEFICIT",
    CREATED = "CREATED",
    COMPLETED = "COMPLETED",
    PART_COMPLETED = "PART_COMPLETED",
}

export enum MasterJobStatus {
    PLANNED = "PLANNED",
    DELAYED = "DELAYED",
    IN_PROGRESS = "IN_PROGRESS",
    LOCKED_AUTO = "LOCKED_AUTO",
}

export enum MasterRequestType {
    SHUTTLE = "SHUTTLE",
    ROUTED_TO = "ROUTED_TO",
    TRANSPORTATION = "TRANSPORTATION",
    JOB = "JOB",
    TEAM_TRANSFER = "TEAM_TRANSFER",
    PASSENGER = "PASSENGER",
}

export interface MasterCancelRequest {
    requestId: string
}

export enum SlotType {
    ResourceReturnToBaseLocationSlot = "ResourceReturnToBaseLocationSlot",
    ResourceSupplyBaseLoadingSlot = "ResourceSupplyBaseLoadingSlot",
    ResourceTransportationSlot = "ResourceTransportationSlot",
    ResourceOperationStepSlot = "ResourceOperationStepSlot",
}

export enum RequestOwnerFilter {
    MY = "MY",
    ALL = "ALL"
}