import { createApi } from '@reduxjs/toolkit/query/react'
import { EquipmentDetails, MechnicPermittedToWorkRequest } from './types'
import { baseQuery } from '../../../../../base-service'
import { ApiRoutes } from '../../../../../utils/api-routes'

export const mechanicDetailsApi = createApi({
    reducerPath: 'mechanicDetailsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getEquipmentDetails: builder.query<EquipmentDetails, string>({
            query: (id) => ApiRoutes.getMechanicEquipmentDetails(id),
        }),
        returnToService: builder.mutation<void, MechnicPermittedToWorkRequest>({
            query: (request) => ({
                url: ApiRoutes.returnToService,
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const { useReturnToServiceMutation, useGetEquipmentDetailsQuery } = mechanicDetailsApi