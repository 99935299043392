import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { DriverNotification, NoticeStatusRequest } from './types'
import { ApiRoutes } from '../../../utils/api-routes'

export const driverNotificationsApi = createApi({
    reducerPath: 'driverNotificationsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getNewNoticeList: builder.query<DriverNotification[], void>({
            query: () => ({
                url: ApiRoutes.getDriverNewNoticeList,
                method: 'GET',
            }),
            structuralSharing: false,
        }),
        noticeStatus: builder.mutation<void, NoticeStatusRequest>({
            query: (body) => ({
                url: ApiRoutes.driverNoticeStatus,
                method: 'POST',
                body: body,
            }),
        }),
    }),
  })
  
  export const {
    useGetNewNoticeListQuery,
    useNoticeStatusMutation,
} = driverNotificationsApi