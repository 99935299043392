import { Text } from "@consta/uikit/Text"
import { Checkbox } from '@consta/uikit/Checkbox';
import { FC, useEffect } from "react";
import { TextField } from '@consta/uikit/TextField';
import { Button } from "@consta/uikit/Button";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useSelector } from "react-redux";
import { formatISO } from "date-fns";
import { RootState } from "../../../../../store";
import { useGetReserveTypesQuery } from "../../../../../mdm/services";
import { Space } from "../../../../../components/Space";
import { SelectDateTime } from "../../../../../components/SelectDateTime";
import { AppBarBottomSheet } from "../../../../../components/AppBarBottomSheet";
import { Select } from '@consta/uikit/Select';
import toast from 'react-hot-toast';
import { useCreateMoveToReserveRequestMutation } from "./services";


interface Props {
    onClose: (update?: boolean) => void
}


type ReasonItem = {
    label: string;
    id: string;
}

type FormData = {
    startNow: boolean
    unknownPeriod: boolean
    reason: ReasonItem
    comment: string
    dateTimeOf: Date
    dateTimeTo: Date
}

export const NewMechanicReserveRequest: FC<Props> = ({ onClose }) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm<FormData>(
        {
            defaultValues: {
                startNow: true,
                unknownPeriod: true
            }
        }
    )
    const equipment = useSelector((state: RootState) => state.mechanic.selectedEquipment)!
    const startNow = watch('startNow')
    const unknownPeriod = watch('unknownPeriod')
    const { data } = useGetReserveTypesQuery()
    const [sendRequest, { isLoading, isError, isSuccess, error }] = useCreateMoveToReserveRequestMutation()

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        await sendRequest({
            dateTimeOf: startNow ? '' : formatISO(data.dateTimeOf),
            dateTimeTo: unknownPeriod ? '' : formatISO(data.dateTimeTo),
            startNow: data.startNow,
            equipmentItemId: equipment.equipmentId,
            title: data.comment,
            type: data.reason!.id
        }) 
    }

    useEffect(() => {
        if (isError) {
            toast.error('Произошла ошибка. Не удалось перевести с резерв технику')  
        }
        if (isSuccess) {
            onClose(true)
        }
    }, [isError, isSuccess])

    return <form
        onSubmit={handleSubmit(onSubmit)}
    >
        <div>
            <AppBarBottomSheet
                title="Перевести в резерв"
                onBackPress={onClose}
            />
            <Space
                direction="vertical"
                size="xl"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                }}>
                <Space
                    direction="vertical"
                    size="s"
                >
                    <Text view="secondary">
                        Начало
                    </Text>
                    <Controller
                        name="startNow"
                        control={control}
                        render={({ field }) => <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(value) => field.onChange(value.e)}
                            label="С линии сейчас"
                        />}
                    />

                    {!startNow ? <SelectDateTime
                        style={{zIndex: 10}}
                        name='dateTimeOf'
                        control={control}
                        rules={{
                            required: !startNow
                        }}
                    /> : <></>
                    }

                </Space>

                <Space
                    direction="vertical"
                    size="s"
                >
                    <Text view="secondary">
                        Окончание
                    </Text>

                    <Controller
                        name="unknownPeriod"
                        control={control}
                        render={({ field }) => <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(value) => field.onChange(value.e)}
                            label="На неопределенный срок"
                        />}
                    />

                    {!unknownPeriod ? <SelectDateTime
                        style={{zIndex: 10}}
                        name='dateTimeTo'
                        control={control}
                        rules={{
                            required: !unknownPeriod
                        }}
                    /> : <></>
                    }
                </Space>

                <Controller
                    name="reason"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Select
                    style={{zIndex:101}}
                        {...field}
                        placeholder="Выберите причину"
                        items={data ? Object.keys(data).map((key): ReasonItem => (
                            {
                                label: data[key],
                                id: key,
                            }
                        )) : []}
                        onChange={(value) => field.onChange(value.value)}
                    />}
                />

                <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => <TextField
                        {...field}
                        onChange={(value) => field.onChange(value.value)}
                        type="textarea"
                        placeholder="Комментарий"
                        rows={5}
                    />}
                />
            </Space>

            <Button
                form="round"
                label="Вывести в резерв"
                disabled={!isDirty || !isValid}
                loading={isLoading}
                style={{
                    backgroundColor: (!isDirty || !isValid) ? '#97b2c4' : '#97b2c4',
                    position: "absolute",
                    bottom: 'var(--space-l)',
                    right: 'var(--space-l)',
                    left: 'var(--space-l)',
                }}
                type="submit"
            />
        </div>
    </form>

}

