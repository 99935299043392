import { FC } from "react"
import { Text } from '@consta/uikit/Text';
import { Space } from "../../../../components/Space";
import { format, parseISO } from "date-fns";
import { DriverSlotDetails } from "./types";


interface Props {
    details: DriverSlotDetails
    style?: React.CSSProperties
}

export const SlotDetails: FC<Props> = ({ details, style }) => {
    return <Space
        direction="vertical"
        size="2xs"
        style={style} >
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Начало</Text>
            <Text view="primary" weight="medium">{format(parseISO(details.dateTimeOf), "dd.MM.yyyy HH:mm")}</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Окончание</Text>
            <Text view="primary" weight="medium">{format(parseISO(details.dateTimeTo), "dd.MM.yyyy HH:mm")}</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Длительность</Text>
            <Text view="primary" weight="medium">{details.duration / 60} мин</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Бригада</Text>
            <Text view="primary" weight="medium">{details.team.title}</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">М/р</Text>
            <Text view="primary" weight="medium">{details.wellPadGroupNumber}</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Куст</Text>
            <Text view="primary" weight="medium">{details.wellPadNumber}</Text>
        </Space>
        <Space spaceBetween>
            <Text view="secondary" weight="medium">Скважина</Text>
            <Text view="primary" weight="medium">{details.wellNumber}</Text>
        </Space>
    </Space>
}