import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../../base-service'
import { ApiRoutes } from '../../../../../utils/api-routes'
import { MechanicMoveToReserveRequest } from './types'

export const mechanicReserveApi = createApi({
    reducerPath: 'mechanicReserveApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createMoveToReserveRequest: builder.mutation<undefined, MechanicMoveToReserveRequest>({
            query: (request) => ({
                url: ApiRoutes.moveToReserve,
                method: 'POST',
                body: request
            }),
        }),
    }),
  })
  
  export const { useCreateMoveToReserveRequestMutation } = mechanicReserveApi