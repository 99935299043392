import { Space } from '../../../../components/Space';
import { Text } from "@consta/uikit/Text"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { RequestStateLabel } from '../../home/components/RequestStateLabel';


export const PassengerRequestHeader = () => {
    const request = useSelector((state: RootState) => state.master.selectedRequest)!

    return <Space
        direction='vertical'
        size='xs'
    >   
        <RequestStateLabel state={request.state} />
        <Text view="primary" weight="medium" size="m">{request.name}</Text>
        <Text view="secondary" weight="medium" size="m">{request.phoneNumber}</Text>
    </Space>
}