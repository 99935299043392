import { createApi } from '@reduxjs/toolkit/query/react'
import { Equipment } from './home/types'
import { GeometryPoint } from '../../components/Map/types'
import { baseQuery } from '../../base-service'
import { ApiRoutes } from '../../utils/api-routes'

export const mechanicApi = createApi({
    reducerPath: 'ssmMechanicApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getEquipments: builder.query<Equipment[], void>({
            query: () => ApiRoutes.getMechanicEquipments,
        }),
        getBaseNodeCoordinates: builder.query<GeometryPoint, void>({
            query: () => ApiRoutes.getBaseNodeCoordinates,
        }),
    }),
  })
  
  export const { useGetEquipmentsQuery, useGetBaseNodeCoordinatesQuery } = mechanicApi