import { createApi } from '@reduxjs/toolkit/query/react'
import { DriverOutOfServiceRequest } from './types'
import { baseQuery } from '../../../base-service'
import { ApiRoutes } from '../../../utils/api-routes'

export const driverOutOfServiceApi = createApi({
    reducerPath: 'ssmDriverOutOfServiceApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createOutOfService: builder.mutation<void, DriverOutOfServiceRequest>({
            query: (request) => ({
                url: ApiRoutes.createOutOfService,
                method: 'POST',
                body: request
            }),
        }),
    }),
  })
  
  export const { useCreateOutOfServiceMutation } = driverOutOfServiceApi