import Sheet, { SheetRef } from 'react-modal-sheet';
import { Space } from '../../Space';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { BottomSheetTitleHeader } from '../../BottomSheetTitleHeader';
import { FC, useRef, useState } from 'react';
import { OilFieldsList } from './OilFieldsList';
import { ClustersList } from './ClustersList';
import { WellsList } from './WellsList';
import { Divider } from '../../Divider';
import { Button } from '@consta/uikit/Button';
import { MdmCluster, MdmOilField, MdmWell } from '../../../mdm/types';
import { Text } from "@consta/uikit/Text"


type SelectedWorkPlace = {
    oilField: MdmOilField
    cluster: MdmCluster
    well: MdmWell | undefined
}

interface Props {
    level: number,
    isOpen: boolean,
    onClose: () => void,
    onSelect: (item: SelectedWorkPlace) => void
}


export const SelectWorkPlaceDialog: FC<Props> = ({ isOpen, onClose, onSelect, level }) => {
    const refBottomSheet = useRef<SheetRef>()

    const [oilField, setOilField] = useState<MdmOilField | undefined>(undefined)
    const [cluster, setCluster] = useState<MdmCluster | undefined>(undefined)
    const [well, setWell] = useState<MdmWell | undefined>(undefined)

    return <Sheet
        ref={refBottomSheet}
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={[window.innerHeight - 65, 0]}
        detent="full-height"
        initialSnap={0}
    >
        <Sheet.Container>
            <Sheet.Header>
                <Theme preset={presetGpnDefault}>
                    <BottomSheetTitleHeader
                        title='Выберите место'
                        onClose={() => {
                            setOilField(undefined)
                            setCluster(undefined)
                            setWell(undefined)
                            refBottomSheet.current?.snapTo(1)
                        }}
                    />
                </Theme>
            </Sheet.Header>
            <Sheet.Content disableDrag={true}>
                <Sheet.Scroller draggableAt="top">
                    <Theme preset={presetGpnDefault}>
                        <Space spaceBetween direction="vertical" style={{ paddingBottom: 80 }} >
                            <>
                                {!oilField && <OilFieldsList
                                    onSelect={(item) => { setOilField(item) }}
                                />}
                                {oilField && !cluster && <ClustersList
                                    oilFiledId={oilField.id}
                                    onSelect={(item) => {
                                        setCluster(item)
                                        if (level === 2) {
                                            onSelect(
                                                {
                                                    oilField: oilField!,
                                                    cluster: item,
                                                    well: undefined
                                                }
                                            )
                                            setOilField(undefined)
                                            setCluster(undefined)
                                        }
                                    }}
                                />}
                                {cluster && !well && <WellsList
                                    clusterId={cluster.id}
                                    onSelect={(item) => {
                                        setWell(item)
                                        onSelect(
                                            {
                                                oilField: oilField!,
                                                cluster: cluster!,
                                                well: item,
                                            }
                                        )
                                        setOilField(undefined)
                                        setCluster(undefined)
                                        setWell(undefined)
                                    }}
                                />}
                            </>
                            <div
                                style={{
                                    backgroundColor: "#ffffff",
                                    position: "fixed",
                                    left: 0,
                                    bottom: 0,
                                    width: '100%',
                                    height: '80px',
                                }}
                            >
                                <Divider />
                                <Text
                                    size='s'
                                    view='ghost'
                                    style={{
                                        paddingTop: 6,
                                        paddingLeft: 'var(--space-l)',
                                        paddingRight: 'var(--space-l)',
                                    }}
                                >
                                    {oilField ? `${oilField.title}` : ''} {cluster ? `/ ${cluster.title}` : ''} {well ? `/ ${well.title}` : ''}
                                </Text>
                                <Space
                                    style={{
                                        paddingTop: 6,
                                        paddingLeft: 'var(--space-l)',
                                        paddingRight: 'var(--space-l)',
                                    }}
                                >
                                    <Button
                                        label="Назад"
                                        view="ghost"
                                        form='round'
                                        disabled={!!!oilField}
                                        style={{
                                            width: '45%'
                                        }}
                                        onClick={() => {
                                            if (cluster) {
                                                setCluster(undefined)
                                            } else if (oilField) {
                                                setOilField(undefined)
                                            }
                                        }}
                                    />
                                    <Button
                                        label="Выбрать"
                                        view="primary"
                                        form='round'
                                        disabled={!!!oilField || !!!cluster}
                                        style={{
                                            width: '45%'
                                        }}
                                        onClick={() => {
                                            onSelect(
                                                {
                                                    oilField: oilField!,
                                                    cluster: cluster!,
                                                    well: undefined
                                                }
                                            )
                                            setOilField(undefined)
                                            setCluster(undefined)
                                            setWell(undefined)
                                        }}
                                    />
                                </Space>
                            </div>
                        </Space>
                    </Theme>
                </Sheet.Scroller>
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
    </Sheet>
}