import { MasterRequestResource } from '../types';
import { FC } from 'react';
import { EmptyDataPlaceholder } from '../../../../components/EmptyDataPlaceholder';
import { MasterRequestState } from '../../home/types';
import { EquipmentStateLabel } from '../../../../components/EquipmentStateLabel';
import { RegisterPlate } from '../../../../components/RegisterPlate';
import { Space } from '../../../../components/Space';
import { Text } from "@consta/uikit/Text"
import { Divider } from '../../../../components/Divider';


interface Props {
    resources: Record<string, MasterRequestResource>
    resourceIds: string[]
}

export const PassengerRequestEquipment: FC<Props> = ({ resources, resourceIds }) => {
    var equipment: MasterRequestResource | undefined

    if (resourceIds.length > 0) {
        equipment = Object.values(resources)[0]
    }

    return <div >
        {equipment ? <Space
            direction='vertical'
            size='xs'
        >
            <Text view='primary' weight="medium">{equipment.title}</Text>

            <Space size='s' style={{paddingBottom: 10}}>
                <EquipmentStateLabel state={equipment.resourceState} />
                <RegisterPlate regNumber={equipment.registrationPlateNumber} />
            </Space>

        </Space> : <></>}

        {resourceIds.length == 0 && <EmptyDataPlaceholder
            title={'Техника не назначена'}
            description={''}
            style={{
                padding: 10,
                backgroundColor: "#F5F5F5",
                borderRadius: 4,
            }}
        />}

    </div>
}