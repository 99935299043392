import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { EditMasterRouteRequest, NewMasterRouteRequest, UpdateMasterRouteRequest } from './types'
import { MasterRequest } from '../../home/types'
import { MdmEquipmentItem, MdmMaterial } from '../../../../mdm/types'
import { parseISO } from 'date-fns'
import { CalculateDurationFromMinutes } from '../../../../utils/dates'
import { ApiRoutes } from '../../../../utils/api-routes'


export const masterRouteRequestApi = createApi({
    reducerPath: 'masterRouteRequestApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createRouteRequest: builder.mutation<undefined, NewMasterRouteRequest>({
            query: (request) => ({
                url: ApiRoutes.createRouteRequest,
                method: 'POST',
                body: request
            }),
        }),
        updateRouteRequest: builder.mutation<undefined, UpdateMasterRouteRequest>({
            query: (request) => ({
                url: ApiRoutes.updateRouteRequest(request.id),
                method: 'POST',
                body: request.body
            }),
        }),
        getRouteRequestDetails: builder.query<EditMasterRouteRequest, string | undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const detailsResponse = await fetchWithBQ(ApiRoutes.getJobRequestDetails(_arg))
                if (detailsResponse.error) {
                    return { error: detailsResponse.error }
                }
                const detailsData = detailsResponse.data as MasterRequest

                const equipmentsResponse = await fetchWithBQ(ApiRoutes.getEquipmentItems)
                if (equipmentsResponse.error) {
                    return { error: equipmentsResponse.error }
                }
                const equipmentsData = equipmentsResponse.data as MdmEquipmentItem[]

                const result = {
                    state: detailsData.state,
                    dateTimeOf: parseISO(detailsData.beginDate),
                    dateTimeTo: parseISO(detailsData.endDate),
                    equipment: equipmentsData.find(element => element.id == detailsData.equipmentItemId),
                    routePoints: detailsData.locations.map((element) => ({
                        id: element.id,
                        oilField: {
                            title : element.wellPadGroupTitle
                        },
                        cluster: {
                            id: element.locationId,
                            title : element.wellPadTitle
                        },
                        comment: element.comment,
                        drurationHours: CalculateDurationFromMinutes({ minutes: element.duration }).drurationHours,
                        drurationMinutes: CalculateDurationFromMinutes({ minutes: element.duration }).drurationMinutes,
                    })),
                } as EditMasterRouteRequest

                return { data: result }
            }
        }),
    }),
})

export const {
    useCreateRouteRequestMutation,
    useGetRouteRequestDetailsQuery,
    useUpdateRouteRequestMutation,
} = masterRouteRequestApi