import { keycloak } from "../Keycloak"
import { baseQuery } from "../base-service"
import { ApiRoutes } from "../utils/api-routes"
import { UserManyRolesError, UserNoRolesError } from "../utils/errors"
import { SelectRoleRequest, User, UserRole } from "./types"
import { createApi } from '@reduxjs/toolkit/query/react'

export async function getKeycloakConfig(): Promise<Record<string, string>> {
  const response = await fetch(ApiRoutes.getKeycloakConfigPwa, {
    method: 'GET',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
  })
  return response.json()
}

export async function fetchXsrfToken() {
  await fetch(ApiRoutes.login, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${keycloak?.token}`
    },
  })
}

export async function logout() {
  await fetch(ApiRoutes.logout, {
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${keycloak?.token}`
    },
  })
}

export const userApi = createApi({
  reducerPath: 'ssmUserApi',
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const userResponse = await fetchWithBQ(ApiRoutes.getUser)

        if (userResponse.error) {
          return { error: userResponse.error }
        }
        const user = userResponse.data as User

        if (user.authorities.length > 1) {
          return { error: UserManyRolesError }
        }
        if (user.authorities.length === 0) {
          return { error: UserNoRolesError }
        }

        const role = user.authorities.at(0)?.authority

        const roleResponse = await fetchWithBQ({
          url: ApiRoutes.selectRole,
          method: 'POST',
          body: { role: role }
        })
        if (roleResponse.error) {
          return { error: roleResponse.error }
        }

        const resultUser = await fetchWithBQ(ApiRoutes.getUser)
        return resultUser.data
          ? { data: resultUser.data as User }
          : { error: resultUser.error! }

      }
    }),
    selectRole: builder.mutation<void, SelectRoleRequest>({
      query: (request) => ({
        url: ApiRoutes.selectRole,
        method: 'POST',
        body: request,
      }),
    }),
  }),
})



export const {
  useGetUserQuery,
  useSelectRoleMutation,
} = userApi
