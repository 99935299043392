import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { ChangeReturnTimeMechanicRequest, OfflineRequest } from './types'
import { ApiRoutes } from '../../../../utils/api-routes'

export const mechanicOfflineRequestsApi = createApi({
    reducerPath: 'mechanicOfflineRequestsApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRequests: builder.query<OfflineRequest[], void>({
            query: () => ApiRoutes.getOutOfServiceRequests,
        }),
        approveRequest: builder.mutation<any, string>({
            query: (id) => ({
                url: ApiRoutes.approveOutOfServiceRequest(id),
                method: 'POST',
                body: {}
            }),
        }),
        declineRequest: builder.mutation<any, string>({
            query: (id) => ({
                url: ApiRoutes.declineOutOfServiceRequest(id),
                method: 'POST',
                body: {}
            }),
        }),
        changeReturnTime: builder.mutation<any, [string, ChangeReturnTimeMechanicRequest]>({
            query: (params) => ({
                url: ApiRoutes.approveOutOfServiceRequest(params[0]),
                method: 'POST',
                body: params[1],
            }),
        }),
    }),
  })
  
  export const {
    useGetRequestsQuery,
    useApproveRequestMutation,
    useDeclineRequestMutation,
    useChangeReturnTimeMutation,
} = mechanicOfflineRequestsApi