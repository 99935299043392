import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { useEffect } from "react"
import { useGetRequestDetailsQuery, useGetTelemetryMutation } from "./services"
import { updateEquipmentPositions } from "../../../components/Map/slice"
import { useInterval } from "react-use"
import { ErrorDataPlaceholder } from "../../../components/ErrorDataPlaceholder"
import { PassengerRequestDetailsSkeleton } from "./components/PassengerRequestDetailsSkeleton"
import { Space } from "../../../components/Space"
import { Divider } from "../../../components/Divider"
import { PassengerRequestEquipment } from "./components/PassengerRequestEquipment"
import { PassengerRequestHeader } from "./components/PassengerRequestHeader"
import { PassengerRequestTimeline } from "../../../components/PassengerRequestTimeline"


export const RequestDetailsPassenger = () => {
    const request = useSelector((state: RootState) => state.master.selectedRequest)!
    const dispatch = useDispatch()

    const { data, error, isLoading, refetch } = useGetRequestDetailsQuery(request.id)
    const [getTelemetry, { data: equipmentTelemetry }] = useGetTelemetryMutation()

    useEffect(() => {
        if (data) {
            const resorcesIds = data.steps.flatMap(element => element.resourceIds)
            if (resorcesIds.length > 0) {
                getTelemetry({ equipmentIds: resorcesIds })
            }
        }
    }, [data])

    useEffect(() => {
        if (equipmentTelemetry?.items) {
            const positions = equipmentTelemetry.items.map(element => {
                return {
                    geoY: element.position?.lat,
                    geoX: element.position?.lng,
                    direction: 0,
                    regNumber: element.regNumber,
                    equipmentId: element.equipmentId,
                }
            })
            dispatch(updateEquipmentPositions(positions))
        }
    }, [equipmentTelemetry])

    useInterval(
        () => {
            refetch()
            if (data) {
                const resorcesIds = data.steps.flatMap(element => element.resourceIds)
                if (resorcesIds.length > 0) {
                    getTelemetry({ equipmentIds: resorcesIds })
                }
            }
        },
        10 * 1000,
    )

    return <div style={{ marginTop: 30 }}>
        {isLoading && <div style={{ textAlign: 'center', }}><PassengerRequestDetailsSkeleton /></div>}

        {error && !isLoading && <ErrorDataPlaceholder title="Произошла ошибка" description="Не удалось загрузить данные о шаге" />}

        {!isLoading && data && <Space
            size="s"
            style={{ padding: 'var(--space-l)' }}
            direction="vertical"
        >
            <PassengerRequestHeader />

            <Divider />

            <PassengerRequestEquipment
                resourceIds={data.steps[0].resourceIds}
                resources={data.resources}
            />

            <PassengerRequestTimeline request={request} />
        </Space>}
    </div>
}