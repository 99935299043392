import { FC, useRef, useState } from "react"
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconRevert } from '@consta/icons/IconRevert';
import { Space } from "../../../../components/Space";
import { useNavigate } from "react-router";
import { Paths } from "../../../../path";
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { IconWrench } from '@consta/icons/IconWrench';
import { IconRoute } from '@consta/icons/IconRoute';
import { IconWellGradients } from '@consta/icons/IconWellGradients';
import { IconLayers } from '@consta/icons/IconLayers';
import { IconTeam } from '@consta/icons/IconTeam';
import { IconOperators } from '@consta/icons/IconOperators';
import { createPortal } from "react-dom";
import { Loader } from '@consta/uikit/Loader';
import { StorageKeys } from "../../../../utils/storage-keys";
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { updateRequestOwnerFilter } from "../../slice";
import { useDispatch, useSelector } from "react-redux";
import { RequestOwnerFilter } from "../types";
import { RootState } from "../../../../store";


interface Props {
    refetch: () => void
    isFetching: boolean
}

type MenuItem = {
    index: number
    label: string
    iconRight: any
}

type FilterItem = string

const filterItems: FilterItem[] = ['Мои заявки', 'Все'];

export const MasterWorkPlanHeader: FC<Props> = ({ refetch, isFetching }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const ref = useRef(null)

    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const items: MenuItem[] = (localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false') ? [
        {
            index: 0,
            label: 'На работу',
            iconRight: IconWrench,
        },
        {
            index: 1,
            label: 'На объезд',
            iconRight: IconRoute,
        },
        {
            index: 2,
            label: 'На перевозку',
            iconRight: IconWellGradients,
        },
        // {
        //     index: 3,
        //     label: 'На материалы',
        //     iconRight: IconLayers,
        // },
        {
            index: 4,
            label: 'На перезд бригады',
            iconRight: IconTeam,
        },
    
    ] : [
        {
            index: 5,
            label: 'Пасс. транспорт',
            iconRight: IconOperators,
        },
    ]

    return <>

        <Space spaceBetween
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
                paddingBottom: 'var(--space-s)',
            }}>
            <Space>
                <Text view="primary" weight="bold" size='2xl'>
                    Заявки
                </Text>
                <Button
                    ref={ref}
                    label="Создать"
                    view="primary"
                    size='s'
                    onClick={() => setIsOpen(!isOpen)}
                />
            </Space>
            <Button
                label="Обновить"
                view="ghost"
                size='s'
                iconLeft={IconRevert}
                onlyIcon
                onClick={refetch}
            />
        </Space>

        {localStorage.getItem(StorageKeys.passangerModeEnabled) == 'true' && <div
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }}
        >

            <ChoiceGroup
                style={{
                    marginTop: 'var(--space-s)',
                }}
                value={ownerFilter == RequestOwnerFilter.MY ? filterItems[0] : filterItems[1]}
                onChange={(e) => {
                    dispatch(updateRequestOwnerFilter(filterItems.findIndex(element => element == e.value) == 0 ? RequestOwnerFilter.MY : RequestOwnerFilter.ALL))
                }}
                width="full"
                view="ghost"
                size="s"
                items={filterItems}
                getItemLabel={(item) => item}
                multiple={false}
                name="MasterRequestsFilter"
            />
        </div>}

        {isFetching ? <Loader size="s" style={{
            marginBottom: 'var(--space-s)',
            marginTop: 'var(--space-s)'
        }} />
            : <div style={{ height: 'var(--space-s)' }} />}

        <ContextMenu
            style={{ zIndex: 300, marginTop: 10 }}
            onClickOutside={() => setIsOpen(false)}
            isOpen={isOpen}
            items={items}
            getItemLeftIcon={(item) => item.iconRight}
            anchorRef={ref}
            direction="downStartLeft"
            onItemClick={(e) => {
                switch (e.item.index) {
                    case 0:
                        navigate(Paths.masterJobRequest)
                        break
                    case 1:
                        navigate(Paths.masterRouteRequest)
                        break
                    case 2:
                        navigate(Paths.masterTransportationRequest)
                        break
                    case 3:
                        navigate(Paths.masterMaterialRequest)
                        break
                    case 4:
                        navigate(Paths.masterTeamTransferRequest)
                        break
                    case 5:
                        navigate(Paths.masterPassTransferRequest)
                        break
                }
            }}
        />

        {isOpen && createPortal(
            <div
                style={{
                    position: 'fixed',
                    inset: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 299,
                }}
            />,
            document.body
        )}
    </>
}