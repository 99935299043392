import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { EditPassengerRequest, NewPassengerTransportRequest, PassengerRequest, UpdatePassengerRequest } from './types'
import { parseISO } from 'date-fns'
import { MdmEquipmentType, MdmRequestPurposes } from '../../../../mdm/types'
import { ApiRoutes } from '../../../../utils/api-routes'

export const masterPassengerTransportRequestApi = createApi({
    reducerPath: 'masterPassengerTransportRequestApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createPassengerTransportRequest: builder.mutation<undefined, NewPassengerTransportRequest>({
            query: (request) => ({
                url: ApiRoutes.createPassengerTransportRequest,
                method: 'POST',
                body: request
            }),
        }),
        updatePassangerRequest: builder.mutation<undefined, UpdatePassengerRequest>({
            query: (request) => ({
                url: ApiRoutes.updatePassangerRequest(request.id),
                method: 'POST',
                body: request.body
            }),
        }),
        getPassengerRequestDetails: builder.query<EditPassengerRequest, string | undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const detailsResponse = await fetchWithBQ(ApiRoutes.getJobRequestDetails(_arg))
                if (detailsResponse.error) {
                    return { error: detailsResponse.error }
                }

                const detailsData = detailsResponse.data as PassengerRequest

                const requestPurposesResponse = await fetchWithBQ(ApiRoutes.getRequestPurposes)
                if (requestPurposesResponse.error) {
                    return { error: requestPurposesResponse.error }
                }
                const requestPurposesData = requestPurposesResponse.data as MdmRequestPurposes[]

                const equipmentTypesResponse = await fetchWithBQ(ApiRoutes.getEquipmentTypes)
                if (equipmentTypesResponse.error) {
                    return { error: equipmentTypesResponse.error }
                }
                const equipmentTypesData = equipmentTypesResponse.data as MdmEquipmentType[]

                const result = {
                    state: detailsData.state,
                    phoneNumber: detailsData.phoneNumber,
                    numberOfPassengers: detailsData.numberOfPassengers,
                    comment: detailsData.comment,
                    fixOrder: detailsData.fixOrder,
                    transferPurpose: requestPurposesData.find(element => element.id == detailsData.requestPurpose),
                    equipmentType: [
                        {
                            count: 1,
                            equipmentType: equipmentTypesData.find(element => element.id == detailsData.equipmentTypeId)
                        }
                    ],
                    pickupLocation: {
                        oilField: {
                            title: detailsData.pickUpLocation.wellPadGroupTitle
                        },
                        cluster: {
                            id: detailsData.pickUpLocation.poiId,
                            title: detailsData.pickUpLocation.wellPadTitle
                        },
                        well: detailsData.pickUpLocation.wellId ? detailsData.pickUpLocation.wellId != "00000000-0000-0000-0000-000000000000" ? {
                            id: detailsData.pickUpLocation.wellId,
                            title: detailsData.pickUpLocation.wellTitle
                        } : undefined : undefined
                    },
                    destinationLocations: detailsData.dropLocations.map((element) => ({
                        oilField: {
                            title: element.wellPadGroupTitle
                        },
                        cluster: {
                            id: element.poiId,
                            title: element.wellPadTitle
                        },
                        well: element.wellId ? element.wellId != "00000000-0000-0000-0000-000000000000" ? {
                            id: element.wellId,
                            title: element.wellTitle
                        } : undefined : undefined
                    }))
                } as EditPassengerRequest

                return { data: result }
            }
        }),
    }),
})

export const {
    useCreatePassengerTransportRequestMutation,
    useUpdatePassangerRequestMutation,
    useGetPassengerRequestDetailsQuery,
} = masterPassengerTransportRequestApi