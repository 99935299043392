import { createApi } from '@reduxjs/toolkit/query/react'
import { BoardingStartedRequest, ExecutionStartedRequest, MovementStartedRequest, RideCompletedRequest, RideContinuedRequest, RideStartedRequest } from './types'
import { baseQuery } from '../../../../base-service'
import { ApiRoutes } from '../../../../utils/api-routes'

export const driverStatesApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        movementStarted: builder.mutation<any, MovementStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.movementStarted,
                method: 'POST',
                body: request
            }),
        }),
        rideStarted: builder.mutation<void, RideStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideStarted,
                method: 'POST',
                body: request,
            }),
        }),
        rideContinued: builder.mutation<void, RideContinuedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideContinued,
                method: 'POST',
                body: request,
            }),
        }),
        rideCompleted: builder.mutation<void, RideCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideCompleted,
                method: 'POST',
                body: request,
            }),
        }),
        boardingStarted: builder.mutation<void, BoardingStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.boardingStarted,
                method: 'POST',
                body: request,
            }),
        }),
        executionStarted: builder.mutation<void, ExecutionStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.executionStarted,
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useMovementStartedMutation,
    useRideCompletedMutation,
    useRideContinuedMutation,
    useRideStartedMutation,
    useBoardingStartedMutation,
    useExecutionStartedMutation,
} = driverStatesApi