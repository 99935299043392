export const ApiRoutes = {
    // [GET]
    // Получить конфиг для работы с КК
    getKeycloakConfigPwa: '/agw/keycloakConfigPwa',

    // [GET]
    // Получить токен xsrf
    login: '/api/rest/core/login',

    // [GET]
    // Выполнить логаут на сервере
    logout: '/api/rest/core/logout',

    // [GET]
    // Получить пользователя
    getUser: '/user',

    // [POST]
    // Выбор роли для пользователя
    selectRole: '/selectRole',

    // [GET]
    // Получить граф дорог для карты
    getMapRoads: '/mobile/maps/road/arc',

    // [GET]
    // Получить точки объектов (poi) для карты
    getMapPoi: '/mobile/mdm/Poi/Page',

    // [GET]
    // Получить данные для текущией смены
    getWorkShift: '/mobile/mdm/CurrentWorkShift',

    // [GET]
    // Получить причины для схода СПТ с линии
    getDisengageTypes: '/mobile/mdm/types?prefix=DISENGAGE',

    // [GET]
    // Получить причины для вывода СПТ в резерв
    getReserveTypes: '/mobile/mdm/types?prefix=RESERVE',

    // [GET]
    // Получить список типов услуг
    getServiceTypes: '/mobile/mdm/serviceType?withDeleted=false',

    // [GET]
    // Получить список операций услуг
    getServiceOperations: (serviceTypeId: string) => `/mobile/mdm/OperationsByServiceTypeId/${serviceTypeId}?withDeleted=false`,

    // [GET]
    // Получить список блоков услуг
    getServiceBlocks: (operationId: string) => `/mobile/mdm/OperationTypesByBlockId/${operationId}?withDeleted=false`,

    // [GET]
    // Получить список месторождений
    getOilFileds: `/mobile/mdm/oilField?withPoiOnly=true`,

    // [GET]
    // Получить список кустов для месторождения
    getClusters: (oilFieldId: string) => `/mobile/mdm/clusterByOilField?oilFieldId=${oilFieldId}`,

    // [GET]
    // Получить список скважин для куста
    getWells: (clusterId: string) => `/mobile/mdm/wellByCluster?clusterId=${clusterId}`,

    // [GET]
    // Получить список типов СПТ (техники)
    getEquipmentTypes: '/mobile/mdm/equipment/type',

    // [GET]
    // Получить список материалов
    getMaterials: '/mobile/mdm/materials',
    
    // [GET]
    // Получить список экземпляров СПТ
    getEquipmentItems: '/mobile/mdm/equipmentItems?withDeleted=false',

    // [GET]
    // Получить список техники, необходимой для выполнения задания
    geActivityTypes: (id: string) => `/mobile/mdm/OperationTypeWithActivityById/${id}`,

    // [GET]
    // Получить список состоний техники (мэппинг на русском)
    getResourceState: '/mobile/mdm/ResourceState',

    // [GET]
    // Получить список целей (заданий) для заявок на ПТС
    getRequestPurposes: '/mobile/mdm/RequestPurposes',

    // [GET]
    // Получить список системных настроек
    getSystemSettings: '/mobile/mdm/SystemSettings',

    // [GET]
    // Получить расписание работа для водителя
    getSchedule: '/mobile/v2/driver/scheduleList',

    // [GET]
    // Получить описание работ текущего назначения для водителя
    getCurrentSlot: '/mobile/v2/driver/currentSlot',

    // [GET]
    // Получить текущие координаты СПТ для водителя
    getEquipmentPosition: '/mobile/driver/equipment/lastPosition',

    // [GET]
    // Получить детальные данные для задачи (назначение водителя)
    getSlotDetails: (id: string) => `/mobile/driver/tasks/step/${id}`,

    // [POST]
    // Создать заявку на сход с линии водителем
    createOutOfService: '/mobile/driver/requests/createGoOutOfServiceRequest',

    // [POST]
    // Зафиксировать факт начала движения водителем
    movementStarted: '/mobile/v2/driver/movementStarted',

    // [POST]
    // Зафиксировать факт завершения движения водителем
    movementCompleted: '/mobile/v2/driver/movementCompleted',

    // [POST]
    // Зафиксировать факт начала загрузки материалами водителем
    loadingStarted: '/mobile/v2/driver/loadingStarted',

    // [POST]
    // Зафиксировать факт завершения загрузки материалами водителем
    loadingCompleted: '/mobile/v2/driver/loadingCompleted',

    // [POST]
    // Зафиксировать факт начала выполнения работ водителем
    startWork: '/mobile/v2/driver/tasks/startWork',

    // [POST]
    // Зафиксировать факт окончания выполнения работ водителем
    endWork: '/mobile/v2/driver/tasks/endWork',

    // [POST]
    // Зафиксировать начало ожидания старта работ водителем
    startDowntime: '/mobile/driver/tasks/startDowntime',

    // [POST]
    // Зафиксировать конец ожидания старта работ водителем
    endDowntime: (id: string) => `/mobile/driver/tasks/endDowntime/${id}`,

    // [POST]
    // Зафиксировать событие продолжить/завершить работы после заявки на сход с линии водителем
    confirmResult: '/mobile/driver/requests/confirmApprovalResultAccepted',

    // [POST]
    // Зафиксировать событие начала поездки водителем
    rideStarted: '/mobile/v2/driver/rideStarted',

    // [POST]
    // Зафиксировать событие продолжения поездки водителем
    rideContinued: '/mobile/v2/driver/rideContinued',

    // [POST]
    // Зафиксировать событие завершения поездки водителем
    rideCompleted: '/mobile/v2/driver/rideCompleted',

    // [POST]
    // Отправить заявку на привязку устройства к технике
    sendBindRequest: '/mobile/v2/driver/restoreDeviceBinding',

    // [GET]
    // Получить расписание заданий для водителя ПТС
    getSchedulePassenger: '/mobile/v2/driver/JobScheduleRequest/passenger',

    // [POST]
    // Зафиксировать факт посадки пассажира водителем
    boardingStarted: '/mobile/v2/driver/boardingStarted',

    // [POST]
    // Зафиксировать факт начала выполенения поездки ПТС
    executionStarted: 'mobile/v2/driver/requestExecutionStarted',

    // [GET]
    // Получить список новых уведомлений для водителя
    getDriverNewNoticeList: '/mobile/v2/driver/newNoticeList',

    // [POST]
    // Прочитать уведолмение водителем
    driverNoticeStatus: '/mobile/v2/driver/noticeStatus',

    // [GET]
    // Получить детальную информацию по заявке ПТС
    getPassengerRequestDetails: (id: string) => `/mobile/v2/driver/JobScheduleRequest/passenger/${id}/view`,

    // [GET]
    // Получить координаты базового узла мастера
    getBaseCoordinates: '/mobile/master/currentWell/coordinates',

    // [GET]
    // Получить план работ для мастера
    getWorkPlan: '/mobile/v2/master/JobScheduleRequest',

    // [GET]
    // Получить план работ только по своим заявкам для мастера
    getWorkPlanPersonal: '/mobile/v2/master/JobScheduleRequest/personal',

    // [DELETE]
    // Удалить заявку на работу мастером
    deleteRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/bulk?ids=${id}`,

    // [POST]
    // Отменить заявку на работу мастером
    cancelRequest: `/mobile/v2/master/cancelRequestExecution`,

    // [GET]
     // Получить список новых уведомлений для мастера
    getMasterNewNoticeList: '/mobile/v2/master/newNoticeList',

    // [POST]
    // Прочитать уведолмение мастером
    masterNoticeStatus: '/mobile/v2/master/noticeStatus',

    // [GET]
    // Получить детальную информацию по заявке на работу мастером
    getMasterRequestDetails: (id: string) => `/mobile/v2/master/JobScheduleRequest/${id}/view`,

    // [POST]
    // Зафиксировать факт начало работы шага операции мастером
    operationStarted: '/mobile/v2/master/operationStarted',

    // [POST]
    // Зафиксировать факт завершения работы шага операции мастером
    operationCompleted: '/mobile/v2/master/operationCompleted',

    // [POST]
    // Зафиксировать факт подтверждения прибытия техники на точку
    arrivalConfirmed: '/mobile/v2/master/arrivalConfirmed',

    // [POST]
    // Получить координаты техники
    getAllTelemetry: `/mobile/getAllTelemetry`,

    // [POST]
    // Зафиксировать факт завершения работ
    workCompleted: 'mobile/v2/master/workCompleted',

    // [POST]
    // Создать заявку на работу мастером
    createJobRequest: '/mobile/v2/master/JobScheduleRequest',

    // [PUT]
    // Обновить заявку на работу мастером
    updateJobRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/${id}`,

    // [GET]
    // Получить детальную информацию по заявке мастером
    getJobRequestDetails: (id: string | undefined) => `/mobile/v2/master/JobScheduleRequest/${id}`,

    // [POST]
    // Создать заявку на материалы мастером
    createMaterialRequest: '/mobile/v2/master/JobScheduleRequest/material',

    // [POST]
    // Обновить заявку на работу мастером
    updateMaterialRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/material/${id}`,

    // [POST]
    // Создать заявку на ПТС мастером
    createPassengerTransportRequest: '/mobile/v2/master/JobScheduleRequest/passenger',

    // [POST]
    // Обновить заявку на ПТС мастером
    updatePassangerRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/passenger/${id}`,

    // [POST]
    // Создать заявку на объезд мастером
    createRouteRequest: '/mobile/v2/master/JobScheduleRequest/routed',

    // [POST]
    // Обновить заявку на объезд мастером
    updateRouteRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/routed/${id}`,

    // [POST]
    // Создать заявку на переезд бригад мастером
    createTeamTransferRequest: '/mobile/v2/master/JobScheduleRequest/teamTransfer',

    // [POST]
    // Обновить заявку на переезд бригад мастером
    updateTeamTransferRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/teamTransfer/${id}`,

    // [POST]
    // Создать заявку на переезд мастером
    createTransportationRequest: '/mobile/v2/master/JobScheduleRequest/transportation',

    // [POST]
    // Обновить заявку на переезд мастером
    updateTransportationRequest: (id: string) => `/mobile/v2/master/JobScheduleRequest/transportation/${id}`,

    // [GET]
    // Получить список техники для механика
    getMechanicEquipments: '/mobile/mechanic/equipment',

    // [GET]
    // Получить координаты базового узла для механика
    getBaseNodeCoordinates: '/mobile/mechanic/baseNode/coordinates',

    // [POST]
    // Привязать устройство к техники механиком
    bindDevice: '/mobile/v2/mechanic/permittedToWork/bindDevice',

    // [GET]
    // Получить детальную информацию о технике
    getMechanicEquipmentDetails: (id: string) => `/mobile/mechanic/equipment/${id}/details`,

    // [POST]
    // Выпустить механиком технику на линию
    returnToService: `/mobile/v2/mechanic/permittedToWork`,

    // [POST]
    // Снять механиком технику с линии
    outOfService: `/mobile/mechanic/outOfService`,

    // [POST]
    // Вывести технику в резерв механиком
    moveToReserve: `/mobile/v2/mechanic/moveToReserve`,

    // [GET]
    // Получить список заявок на привязку техники к устройству
    getBindEquipmentRequests: '/mobile/v2/mechanic/bindingRequestList',

    // [POST]
    // Отклонить механиком заявку на привязку
    declineDeviceBinding: `/mobile/v2/mechanic/declineDeviceBinding`,

    // [POST]
    // Подтвердить механиком заявку на привязку
    approveDeviceBinding: `/mobile/v2/mechanic/approveDeviceBinding`,

    // [GET]
    // Получить список заявок на сход с линии
    getOutOfServiceRequests: '/mobile/mechanic/request/incoming',

    // [POST]
    // Подтвердить механиком заявку на сход с линии
    approveOutOfServiceRequest: (id: string) => `/mobile/v2/mechanic/request/goOutOfService/${id}/approve`,

    // [POST]
    // Отклонить механиком заявку на привязку
    declineOutOfServiceRequest: (id: string) => `/mobile/mechanic/request/${id}/decline`,
} as const