import { useEffect, useRef, useState } from "react";
import { SsmMap } from "../../../components/Map"
import { ProfileSideBar } from "../../../components/ProfileSideBar"
import { Button } from "@consta/uikit/Button";
import { IconHamburger } from '@consta/icons/IconHamburger';
import { useGetBaseCoordinatesQuery, useGetWorkPlanQuery } from "../services";
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import { MasterWorkPlanHeader } from "./components/MasterWorkPlanHeader";
import { MasterWorkPlanSkeleton } from "./components/MasterWorkPlanSkeleton";
import { ErrorDataPlaceholder } from "../../../components/ErrorDataPlaceholder";
import { EmptyDataPlaceholder } from "../../../components/EmptyDataPlaceholder";
import { MasterWorkPlan } from "./components/MasterWorkPlan";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { RequestDetails } from "../RequestDetails";
import { updateSelectedRequest } from "../slice";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { BottomSheetDefaultHeader } from "../../../components/BottomSheetDefaultHeader";
import { updateEquipmentPositions, updateManualPoints } from "../../../components/Map/slice";
import { useInterval } from "react-use";
import { masterRequestDetailsApi } from "../RequestDetails/services";
import { Geometry } from "../../../components/Map/types";
import { useGetNewNoticeListQuery } from "../notifications/services";
import { MasterNotificationView } from "../notifications";
import toast from "react-hot-toast";
import { swRegistration } from "../../../notifications";
import { StorageKeys } from "../../../utils/storage-keys";
import { RequestDetailsPassenger } from "../RequestDetailsPassenger";
import { RequestOwnerFilter } from "./types";


export const MasterHome = () => {
    const dispatch = useDispatch()
    const workPlanRef = useRef<SheetRef>()
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const selectedRequest = useSelector((state: RootState) => state.master.selectedRequest)
    const centerPoint = useSelector((state: RootState) => state.map.centerPoint)
    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const { data: baseCoordinates } = useGetBaseCoordinatesQuery()
    const { data: workScheduleData, error: workScheduleError, isLoading: workScheduleIsLoading, isFetching: workScheduleIsFetching, refetch: refetchWorkSchedule } = useGetWorkPlanQuery(ownerFilter == RequestOwnerFilter.MY)
    const { isFetching: isFetchingRequestDetails } = masterRequestDetailsApi.endpoints.getRequestDetails.useQueryState(selectedRequest?.id ?? '');
    const { data: notifications, refetch: refetchNotifications } = useGetNewNoticeListQuery()

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            notifications.forEach(element => {
                // show local toast
                toast((t) => (
                    <MasterNotificationView
                        onClose={() => toast.dismiss(t.id)}
                        notification={{
                            notificationId: element.notificationId,
                            text: element.text,
                        }} />
                ), {
                    duration: 30000,
                    id: element.notificationId,
                    className: 'notifictaion-toast',
                })

                // show push on mobile device
                const options = { body: element.text }
                swRegistration?.showNotification('Новое уведомление!', options)
            })

        }
    }, [notifications])

    useEffect(() => {
        if (centerPoint) {
            workPlanRef.current?.snapTo(1)
        }
    }, [centerPoint])

    useInterval(
        () => {
            refetchWorkSchedule()
            refetchNotifications()
        },
        30 * 1000,
    )

    useEffect(() => {
        if (selectedRequest) {
            document.getElementById('workPlanBottomSheet')?.scroll(0, 0)
        }
    }, [selectedRequest])

    useEffect(() => {
        const manualPoints: Geometry[] = []

        workScheduleData?.requests.forEach(function (element) {
            element.jobs?.forEach(function (element) {
                if (element.poiTypes.includes('MANUAL_POINT')) {
                    manualPoints.push({
                        type: "Point",
                        coordinates: [
                            element.destinationPoint.lng,
                            element.destinationPoint.lat,
                        ]
                    })
                }
            });
        });

        if (manualPoints.length > 0) {
            dispatch(updateManualPoints(
                {
                    "type": "FeatureCollection",
                    "features": manualPoints.map((element) => ({
                        "type": "Feature",
                        "geometry": element,
                        "properties": {
                            "title": `Точка на карте ${Number(element.coordinates[0]).toFixed(2)}, ${Number(element.coordinates[1]).toFixed(2)}`,
                        }
                    }))
                }
            ))
        } else {
            dispatch(updateManualPoints(undefined))
        }

    }, [workScheduleData])

    return (
        <>
            <SsmMap center={baseCoordinates} />

            <Button
                label="Меню"
                view="ghost"
                iconLeft={IconHamburger}
                onlyIcon
                onClick={() => setIsProfileOpen(true)}
                style={{
                    backgroundColor: "#ffffff",
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                }}
            />

            <ProfileSideBar
                isOpen={isProfileOpen}
                onClickOutside={() => setIsProfileOpen(false)}
            />

            <Sheet
                style={{ zIndex: 5 }}
                ref={workPlanRef}
                isOpen={true}
                onClose={() => { workPlanRef.current?.snapTo(1) }}
                snapPoints={[window.innerHeight - 65, 95]}
                detent="full-height"
                initialSnap={0}
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <Theme preset={presetGpnDefault}>
                            <BottomSheetDefaultHeader
                                onClose={selectedRequest
                                    ? () => {
                                        dispatch(updateSelectedRequest(undefined))
                                        dispatch(updateEquipmentPositions([]))
                                        workPlanRef.current?.snapTo(0)
                                    }
                                    : undefined}
                                isFetching={isFetchingRequestDetails}
                            />
                            {!selectedRequest && <MasterWorkPlanHeader
                                refetch={() => {
                                    refetchWorkSchedule()
                                    refetchNotifications()
                                }}
                                isFetching={workScheduleIsFetching && !workScheduleIsLoading} />}
                        </Theme>

                    </Sheet.Header>
                    <Sheet.Content>
                        <Sheet.Scroller draggableAt="both" id="workPlanBottomSheet">
                            <Theme preset={presetGpnDefault}>
                                {selectedRequest && localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false' && <RequestDetails />}

                                {selectedRequest && localStorage.getItem(StorageKeys.passangerModeEnabled) == 'true' && <RequestDetailsPassenger />}

                                {workScheduleIsLoading && !selectedRequest && <MasterWorkPlanSkeleton />}

                                {workScheduleError && !workScheduleIsLoading && <ErrorDataPlaceholder
                                    title="Произошла ошибка"
                                    description="Не удалось загрузить список заявок"
                                />}

                                {(!workScheduleIsLoading && workScheduleData && (!workScheduleData.requests || workScheduleData.requests.length === 0))
                                    && <EmptyDataPlaceholder
                                        style={{
                                            paddingLeft: 'var(--space-l)',
                                            paddingRight: 'var(--space-l)',
                                            paddingTop: 20,
                                        }}
                                        title="Пусто"
                                        description="У вас нет назначенных заявок или все заявки выполнены"
                                    />}

                                {(!workScheduleIsLoading && !workScheduleError && workScheduleData && workScheduleData.requests.length > 0)
                                    && <MasterWorkPlan
                                        data={workScheduleData}
                                        hidden={!!selectedRequest}
                                        onUpdate={() => {
                                            refetchWorkSchedule()
                                        }}
                                    />}
                            </Theme>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>

            </Sheet>
        </>
    )
}