import { useLocation, useNavigate, useParams } from "react-router"
import toast, { Toaster } from 'react-hot-toast';
import { AppBar } from "../../../../components/AppBar";
import { Text } from '@consta/uikit/Text';
import { Space } from "../../../../components/Space";
import { SelectWorkPlace } from "../../../../components/SelectWorkPlace";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "@consta/uikit/Button";
import { Divider } from "../../../../components/Divider";
import { WorkPlaceValue } from "../MasterJobRequest/types";
import { SelectDateTime } from "../../../../components/SelectDateTime";
import { SelectedEquimpentTypeCount } from "../../../../components/SelectEquipmentType/types";
import { SelectEquipmentType } from "../../../../components/SelectEquipmentType";
import { SelectedDurationValue } from "../../../../components/SelectDuration/types";
import { SelectDuration } from "../../../../components/SelectDuration";
import { useCreateTransportationRequestMutation, useGetTransportationRequestDetailsQuery, useUpdateTransportationRequestMutation } from "./services";
import { useGetWorkPlanQuery } from "../../services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RequestDetailsSkeleton } from "../components/RequestDetailsSkeleton";
import { addHours, isBefore } from "date-fns";
import { MasterRequestState, RequestOwnerFilter } from "../../home/types";

type FormData = {
    loadPlace: WorkPlaceValue
    dateTimeOf: Date
    loadDuration: SelectedDurationValue
    unloadPlace: WorkPlaceValue
    dateTimeTo: Date
    unloadDuration: SelectedDurationValue
    unloadEquipmentTypes: SelectedEquimpentTypeCount[]
    loadEquipmentTypes: SelectedEquimpentTypeCount[]
    transportationEquipmentTypes: SelectedEquimpentTypeCount[]
}


export const MasterTransportationRequest = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { requestId } = useParams()

    const ownerFilter = useSelector((state: RootState) => state.master.requestOwnerFilter)

    const isCopyMode = location.pathname.includes('copy')
    const isEditMode = location.pathname.includes('edit') && requestId

    const { data: detailsData, error: detailsError, isLoading: detailsIsLoading } = useGetTransportationRequestDetailsQuery(requestId, { skip: !requestId })
    const [sendRequest, { isLoading: sendIsLoading, isError: sendIsError, isSuccess: sendIsSuccess }] = useCreateTransportationRequestMutation()
    const [updateRequest, { isLoading: updateIsLoading, isError: updateIsError, isSuccess: updateIsSuccess }] = useUpdateTransportationRequestMutation()
    const { refetch: refetchWorkSchedule } = useGetWorkPlanQuery(ownerFilter == RequestOwnerFilter.MY, { skip: !sendIsSuccess && !updateIsSuccess })

    const [editDisabled, setEditDisabled] = useState<boolean>(false)
    
    const teamId = useSelector((state: RootState) => state.master.teamId)

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        clearErrors,
        formState: { isDirty, isValid, errors },
    } = useForm<FormData>({
        defaultValues: {
            transportationEquipmentTypes: [
                {
                    equipmentType: undefined,
                    count: 1
                }
            ],
            loadDuration: {
                drurationHours: 0,
                drurationMinutes: 0,
            },
            unloadDuration: {
                drurationHours: 0,
                drurationMinutes: 0,
            },
        }
    })

    const dateTimeTo = watch('dateTimeTo')

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const requestData = {
            state: detailsData?.state ? detailsData?.state : MasterRequestState.CREATED,
            teamId: teamId,
            dateTimeOf: data.dateTimeOf.toISOString(),
            dateTimeTo: data.dateTimeTo.toISOString(),
            isSignificantChanges: false,
            title: `Перевозка из ${data.loadPlace.oilField.title} на ${data.unloadPlace.oilField.title}`,
            name: `Перевозка из ${data.loadPlace.oilField.title} на ${data.unloadPlace.oilField.title}`,
            locations: [
                {
                    id: detailsData?.loadLocationId,
                    duration: data.loadDuration.drurationMinutes + (data.loadDuration.drurationHours * 60),
                    locationId: data.loadPlace.cluster.id,
                    poiId: data.loadPlace.cluster.id,
                    priority: 50
                },
                {   
                    id: detailsData?.unloadLocationId,
                    duration: Number(data.unloadDuration.drurationMinutes) + Number((data.unloadDuration.drurationHours * 60)),
                    locationId: data.unloadPlace.cluster.id,
                    poiId: data.unloadPlace.cluster.id,
                    priority: 50
                }
            ],
            equipmentTypeParamsTransportations: data.transportationEquipmentTypes?.map((element) => ({
                equipmentTypeId: element.equipmentType.id,
                countType: 'MIN',
                count: element.count
            })),
            equipmentTypeParamsUnloading: data.unloadEquipmentTypes?.map((element) => ({
                equipmentTypeId: element.equipmentType.id,
                countType: 'MIN',
                count: element.count
            })),
            equipmentTypeParamsLoading: data.loadEquipmentTypes?.map((element) => ({
                equipmentTypeId: element.equipmentType.id,
                countType: 'MIN',
                count: element.count
            })),
        }

        if (isEditMode) {
            await updateRequest({
                id: requestId,
                body: requestData,
            })
        } else {
            await sendRequest(requestData)
        }
    }

    useEffect(() => {
        if (sendIsError || updateIsError) {
            toast.error('Произошла ошибка. Не удалось создать заявку')
        }
        if (sendIsSuccess || updateIsSuccess) {
            refetchWorkSchedule()
            navigate(-1)
        }
    }, [sendIsError, updateIsError, sendIsSuccess, updateIsSuccess])

    useEffect(() => {
        if (detailsData) {
            if (isCopyMode) {
                setValue("dateTimeOf", addHours(detailsData.dateTimeOf, 24))
                setValue("dateTimeTo", addHours(detailsData.dateTimeTo, 24))
            } else {
                setValue("dateTimeOf", detailsData.dateTimeOf)
                setValue("dateTimeTo", detailsData.dateTimeTo)
            }
            setValue("loadDuration", detailsData.loadDuration)
            setValue("unloadDuration", detailsData.unloadDuration)
            setValue("loadEquipmentTypes", detailsData.loadEquipmentTypes)
            setValue("unloadEquipmentTypes", detailsData.unloadEquipmentTypes)
            setValue("transportationEquipmentTypes", detailsData.transportationEquipmentTypes)
            setValue("loadPlace", detailsData.loadPlace)
            setValue("unloadPlace", detailsData.unloadPlace)

            const disabled =
                [
                    MasterRequestState.IN_PROGRESS,
                    MasterRequestState.COMPLETED,
                    MasterRequestState.NOT_PERFORMED,
                ].includes(detailsData.state)
            setEditDisabled(disabled)
        }
    }, [detailsData])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingBottom: 80 }}>
            <AppBar
                style={{ zIndex: 2 }}
                title="Заявка на перевозку"
                onBackPress={() => navigate(-1)}
            />

            {detailsIsLoading && !detailsError && <RequestDetailsSkeleton />}

            {!detailsIsLoading && !detailsError && <Space
                direction="vertical"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                    marginTop: 'var(--space-l)',
                    paddingTop: 60,
                }}
            >
                <Text view="primary" size="s" weight="semibold" spacing="xs">ПОГРУЗКА</Text>

                <SelectWorkPlace
                    disabled={editDisabled}
                    level={2}
                    style={{ zIndex: 10 }}
                    name='loadPlace'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Начать не ранее'
                    style={{ zIndex: 10 }}
                    name='dateTimeOf'
                    control={control}
                    rules={{ required: true, validate: (field: Date) => isBefore(field, dateTimeTo) }}
                />

                <SelectDuration
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='loadDuration'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectEquipmentType
                    disabled={editDisabled}
                    title='Техника для погрузки / закачки'
                    style={{ zIndex: 10 }}
                    name='loadEquipmentTypes'
                    control={control}
                    rules={{ required: false }}
                />

                <Divider />

                <Text view="primary" size="s" weight="semibold" spacing="xs">ПЕРЕВОЗКА</Text>

                <SelectEquipmentType
                    disabled={editDisabled}
                    title='Техника для перевозки'
                    style={{ zIndex: 10 }}
                    name='transportationEquipmentTypes'
                    control={control}
                    rules={{ required: true }}
                />

                <Divider />

                <Text view="primary" size="s" weight="semibold" spacing="xs">РАЗГРУЗКА</Text>

                <SelectWorkPlace
                    disabled={editDisabled}
                    level={2}
                    style={{ zIndex: 10 }}
                    name='unloadPlace'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectDateTime
                    disabled={editDisabled}
                    label='Закончить не позднее'
                    style={{ zIndex: 10 }}
                    name='dateTimeTo'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectDuration
                    disabled={editDisabled}
                    style={{ zIndex: 10 }}
                    name='unloadDuration'
                    control={control}
                    rules={{ required: true }}
                />

                <SelectEquipmentType
                    disabled={editDisabled}
                    title='Техника для разгрузки / раскачки'
                    style={{ zIndex: 10 }}
                    name='unloadEquipmentTypes'
                    control={control}
                    rules={{ required: false }}
                />

            </Space>}
        </div>

        <div
            style={{
                backgroundColor: "#ffffff",
                position: "fixed",
                left: 0,
                bottom: 0,
                width: '100%',
                height: '60px',
            }}
        >
            <Divider />
            <div
                style={{
                    paddingTop: 6,
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Button
                    form="round"
                    disabled={(!requestId && !isDirty) || !isValid}
                    loading={sendIsLoading || updateIsLoading}
                    label={isEditMode ? 'Сохранить' : 'Создать'} 
                    view={isEditMode ? 'secondary' : 'primary'}
                    width="full"
                    type="submit"
                />
            </div>
        </div>
    </form>
}