import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { EquipmentTelemetry, MasterRequestDetails, MasterRequestResource, MasterRequestStep, TelemetryRequest } from './types'
import { parseISO } from 'date-fns'
import { ApiRoutes } from '../../../utils/api-routes'

export const masterRequestDetailsPassengerApi = createApi({
    reducerPath: 'masterRequestDetailsPassengerApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getRequestDetails: builder.query<MasterRequestDetails, string>({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {

                type responseType = {
                    steps: Record<string, MasterRequestStep>
                    resources: Record<string, MasterRequestResource>
                }

                const mainResponse = await fetchWithBQ(ApiRoutes.getMasterRequestDetails(id))
                if (mainResponse.error) {
                    return { error: mainResponse.error }
                }

                const raw = mainResponse.data as responseType
                
                const result = {
                    steps: Object.keys(raw.steps).map((key) => {
                        const val = raw.steps[key]
                        val.id = key
                        return val
                    }).sort((a, b) => parseISO(a.dateTimeTo).getTime() - parseISO(b.dateTimeOf).getTime()),
                    resources: raw.resources
                } as MasterRequestDetails

                return { data: result}
            }
        }),
        getTelemetry: builder.mutation<EquipmentTelemetry, TelemetryRequest>({
            query: (request) => ({
                url: ApiRoutes.getAllTelemetry,
                method: "POST",
                body: request
            }),
        }),
    }),
})

export const {
    useGetRequestDetailsQuery,
    useGetTelemetryMutation,
} = masterRequestDetailsPassengerApi