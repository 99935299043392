import { FC } from "react"
import { Space } from "../../../components/Space"
import { Text } from '@consta/uikit/Text';
import { DriverPassengerRequest } from "../home/types";
import { Divider } from "../../../components/Divider";
import { PassengerRequestTimeline } from "../../../components/PassengerRequestTimeline";


interface Props {
    request: DriverPassengerRequest
}

export const PassengerRequestDetails: FC<Props> = ({ request }) => {
    return <Space
        size="s"
        direction="vertical"
        style={{ padding: 'var(--space-l)' }}
    >
        <Text view="primary" weight="medium" size="m">{request.name}</Text>
        <Text view="secondary" weight="medium" size="m">{request.phoneNumber}</Text>

        <Divider />

        <PassengerRequestTimeline request={request} />
    </Space>
}