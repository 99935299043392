import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../base-service'
import { ConfirmResultRequest, DriverStartDowntimeRequest, LoadingCompletedRequest, LoadingStartedRequest, MovementCompletedRequest, MovementStartedRequest, RideCompletedRequest, RideContinuedRequest, RideStartedRequest } from './types'
import { ApiRoutes } from '../../../utils/api-routes'

export const driverStatesApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        movementStarted: builder.mutation<any, MovementStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.movementStarted,
                method: 'POST',
                body: request
            }),
        }),
        movementCompleted: builder.mutation<any, MovementCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.movementCompleted,
                method: 'POST',
                body: request
            }),
        }),
        loadingStarted: builder.mutation<any, LoadingStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.loadingStarted,
                method: 'POST',
                body: request
            }),
        }),
        loadingCompleted: builder.mutation<any, LoadingCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.loadingCompleted,
                method: 'POST',
                body: request
            }),
        }),
        startWork: builder.mutation<void, undefined>({
            query: () => ({
                url: ApiRoutes.startWork,
                method: 'POST',
            }),
        }),
        endWork: builder.mutation<void, undefined>({
            query: () => ({
                url: ApiRoutes.endWork,
                method: 'POST',
            }),
        }),
        startDowntime: builder.mutation<void, DriverStartDowntimeRequest>({
            query: (request) => ({
                url: ApiRoutes.startDowntime,
                method: 'POST',
                body: request
            }),
        }),
        endDowntime: builder.mutation<void, string>({
            query: (id) => ({
                url: ApiRoutes.endDowntime(id),
                method: 'POST',
            }),
        }),
        confirmResult: builder.mutation<void, ConfirmResultRequest>({
            query: (request) => ({
                url: ApiRoutes.confirmResult,
                method: 'POST',
                body: request,
            }),
        }),
        rideStarted: builder.mutation<void, RideStartedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideStarted,
                method: 'POST',
                body: request,
            }),
        }),
        rideContinued: builder.mutation<void, RideContinuedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideContinued,
                method: 'POST',
                body: request,
            }),
        }),
        rideCompleted: builder.mutation<void, RideCompletedRequest>({
            query: (request) => ({
                url: ApiRoutes.rideCompleted,
                method: 'POST',
                body: request,
            }),
        }),
    }),
})

export const {
    useMovementStartedMutation,
    useMovementCompletedMutation,
    useLoadingStartedMutation,
    useLoadingCompletedMutation,
    useStartWorkMutation,
    useEndWorkMutation,
    useStartDowntimeMutation,
    useEndDowntimeMutation,
    useConfirmResultMutation,
    useRideCompletedMutation,
    useRideContinuedMutation,
    useRideStartedMutation,
 } = driverStatesApi